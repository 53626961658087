@import '@src/styles/constants.scss';
@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Lato;
  src: url(/fonts/lato-black.woff2);
  font-weight: 900;
}
@font-face {
  font-family: Lato;
  src: url(/fonts/lato-heavy.woff2);
  font-weight: 800;
}
@font-face {
  font-family: Lato;
  src: url(/fonts/lato-bold.woff2);
  font-weight: 700;
}
@font-face {
  font-family: Lato;
  src: url(/fonts/lato-regular.woff2);
  font-weight: 500;
}

.Width-Wrapper {
  margin: 0 auto;
  /* width: 85%; */
  max-width: $content-width;
  width: calc(100% - 30px);

  @include media(720px) {
    width: 100%;
    padding-left: scaledPixels(72);
    padding-right: scaledPixels(72);
    box-sizing: border-box;
  }
}

.wrap_else {
  margin: 0 auto;
  width: 90%;
  max-width: 1400px;
}

p,
h1,
h2,
h3,
h4,
h5 {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  margin: 0;
  padding: 0;
}

html {
  font-size: 14px;
}

body {
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  line-height: 1.3;
  color: $BrightGray;
  background-color: $CatskillWhite4;
  /* overflow-x: hidden;
  float: left; */
}

section,
footer {
  float: left;
  width: 100%;
}

section {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  text-decoration: none;
  color: $Gravel;
}

h1,
h2 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 4rem;
}

h1,
h2,
h3 {
  color: $Midnight;
}

h3 {
  font-size: 2.5rem;
  font-weight: 900;
}

h4 {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 5px 0;
}

button {
  border: none;
  background-color: transparent;
}

button:focus {
  outline: none;
}

.credit_list_wrap {
  display: flex;
  justify-content: space-between;
}

.reg_label {
  margin-bottom: 10px;
}

.file_input {
  margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  align-self: baseline;
}

.file_input input {
  display: none;
}

.file_input .file_input_btn {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: $RockBlue;
  border: 1px solid $CodGray;
  position: relative;
  margin-right: 15px;
}

.file_input .file_input_btn:after,
.file_input .file_input_btn:before {
  display: block;
  content: '';
  position: absolute;
  background-color: $CodGray;
  left: 18px;
  top: 12px;
  width: 2px;
  height: 14px;
  border-radius: 3px;
}

.file_input .file_input_btn:after {
  transform: rotate(90deg);
}

@include media(720px) {

  .__jivoMobileButton {
    bottom: scaledPixels(130) !important;
  }
}

.react-datepicker__header {
  background: #1c2a40;
  padding-top: 16px;
}

.react-datepicker__navigation {
  top: 17px;
}

.react-datepicker__day-name,
.react-datepicker__time-name {
  color: #fff;
}

.react-datepicker {
  border: 1px solid #AFC3D5;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background: #996c95;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background: #996c95;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  display: none;
}

.react-datepicker-popper{
  z-index: 999;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #1c2a40;
}

.react-datepicker__header__dropdown--select {
  margin-top: -1px;
  margin-bottom: 6px;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  background: inherit;
  border: none;
  font-size: 14px;
  color: #fff;
  fill: #fff;
  outline: none;
  text-transform: capitalize;

  option {
    color: #0F1721;
  }
}

.slider-overlay{
  @include tablet{
    width: 85vw;
    .slick-dots{
      bottom: -40px;
      .slick-active{
        button{
          &:before{
            background: #FEFEFE;
            opacity: 1;
            width: 18px;
          }
        }
      }
      li{
        button{
          &:before{
            background: #FEFEFE;
            opacity: 0.3;
          }
        }
      }
    }
  }
}

//#jvlabelWrap, .wrap_mW {
//  display: none!important;
//}
//
//.__jivoMobileButton{
//  display: none !important;
//}

.rejectWrapper{
  height: 100%;
  margin: calc(35vh - 363px / 2) auto 0;
  max-width: 620px;
  border-radius: 10px;
  background: #1C2A40;
  padding: 30px;
  @include mobile{
    margin: 0 15px 30px;
  }
  .input-range__track{
    background-color: #FEFEFE;
    border-radius: 4px;
    height: 8px;
  }
  .input-range__track--active{
    background-color: #1E9C58;
  }
  .input-range{
    margin-bottom: 30px;
  }
  .input-range__slider-container .input-range__label--value{
    display: none;
  }
  .input-range__label{
    color: #FEFEFE;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
  .input-range__label--max .input-range__label-container{
    left: 0;
  }
  .input-range__label--min{
    left: 14px;
  }
  .input-range__label-container {
    top: 8px;
  }
    .input-range__slider{
    width: 25px;
    height: 25px;
    background-color: #4DB35E;
    border: none;
    top: -7px;
    left: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before{
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
    }
  }
}

.rangeContainer{
  margin-bottom: 70px;
}

.rangeContainer_money{
  .input-range__label-container{
    &:after{
      content: ' P';
    }
  }
}

.rangeContainer_days{
  .input-range__label--max .input-range__label-container{
    &:after{
      content: ' дней';
    }
  }
  .input-range__label--min .input-range__label-container{
    &:after{
      content: ' дней';
    }
  }
}

.logo__main{
  height: 40px;
}

.sliderExp{
  .input-range__track--active{
    background-color: #5E4570 !important;
    height: 6px;
  }
  .input-range__slider{
    width: 15px;
    height: 15px;
    background: #ffffff;
    filter: drop-shadow(2px 2px 5px #F76083);
    border: none;
    top: -2px;
  }
  .input-range__label-container{
    color: #939BB3;
    font-size: 12px;
  }
  .input-range__track{
    height: 6px;
    background-color: #D9D9D9;
    border-radius: 3px;
  }
  .input-range__label--value{
    display: none;
  }
  .input-range__label--max {
    right: 15px;
  }
  .input-range__label--max {
    right: 15px;
  }
}

.sliderGreen{
  .input-range__track--active{
    background-color: #69E05F !important;
  }
  .input-range__slider{
    background-color: #69E05F;
    filter: none;
  }
}

.sliderOrange{
  .input-range__track--active{
    background-color: #FFBD9A !important;
  }
  .input-range__slider{
    background-color: #FFBD9A;
    filter: none;
  }
}
