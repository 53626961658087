$Selago: #f3f7fd;
$BrightGray: #3b424c;
$Gravel: #48444a;
$Black: #000000;
$Gray: gray;
$LightGray: #616161;
$WhiteBase: #ffffff;
$WhiteLight: #fefefe;
$WhiteDark: #fdfdfd;
$WhiteLilac: #f3f5fa;
$CloudBurst: #1c2a40;
$CodGray: #111111;
$Midnight: #001528;
$RockBlue: #9db7cb;
$SlateGray: #6b798e;
$SlateGray2: #616f85;
$SlateGray3: #6b798f;
$LinkWater2: #D1DCE7;
$LinkWater: #e0e7f4;
$Selago: #f9fbfe;
$CardMainWhite: #fafbfd;
$CardMainWhite2: #FAFBFF;
$CatskillWhite1: #f9fafc;
$CatskillWhite2: #e4e8f2;
$CatskillWhite3: #e3e7f1;
$CatskillWhite4: #f2f4f9;
$CatskillWhite5: #ebeef5;
$AthensGray: #f6f7f9;
$Alabaster: #fafafa;
$Casper: #aec2d4;
$CasperLight: #afc3d5;
$BaliHai: #7e94a7;
$Zircon: #fcfdff;
$Ghost: #caced9;
$ShuttleGray: #585f69;
$BrinkPink: #f66082;
$FrenchRose: #f2466e;
$CoralTree: #a36271;
$Mandy: #ea597a;
$ChestnutRose: #cc4e6c;
$SeaPink: #eea8b8;
$greyRed: #cd4e6c;
$TickleMePink: #ff7594;
$TickleMePinkLight: #f992aa;
$AlizarinCrimson: #e31e24;
$Bordo: #F82E3A;
$Bordo2: #E51A1A;
$Error: #f76083;
$Illusion: #f5b3c2;
$Lynch: #67788f;
$GullGray: #939bb3;
$Vulcan: #0f1721;
$Seashell: #f1f1f1;
$EbonyClay: #272f3c;
$Mischka: #cfd2db;
$SantasGray: #929ab2;
$PigeonPost: #c1c9df;
$Waterloo: #7a8091;
$Botticelli: #cad6e4;
$Geyser: #d3d8e3;
$Comet: #546178;
$BaliHai: #8c9bb4;
$PickledBluewood: #384561;
$Green: #4DB35E;
