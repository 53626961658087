@import "src/styles/colors";

@keyframes dot-flashing {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }

}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 54px - 71px);
}

.points {
  height: 12px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.point {
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: $CloudBurst;
  display: inline-block;

  &.first {
    animation: dot-flashing 1s infinite ease;
    animation-delay: 0s;
  }

  &.second {
    animation: dot-flashing 1s infinite ease;
    animation-delay: 0.166s;
  }

  &.third {
    animation: dot-flashing 1s infinite ease;
    animation-delay: .333s;
  }

  &.forth {
    animation: dot-flashing 1s infinite ease;
    animation-delay: 0.5s;
  }
}

.title {
  margin-top: 8px;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}