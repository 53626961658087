@mixin inch13() {
  @media all and (max-width: 1600px) {
    @content;
  }
}

$smallMobile: 450px;
$mobile: 720px;
$tablet: 1080px;
$desktop: 1400px;

@mixin smallMobile {
  @media (max-width: $smallMobile) {
    @content
  }
}

@mixin mobile {
  @media (max-width: $mobile) {
    @content
  }
}

@mixin tablet {
  @media (max-width: $tablet) {
    @content
  }
}

@mixin desktop {
  @media (max-width: $desktop) {
    @content
  }
}
