@import 'src/styles/colors.scss';
@import 'src/styles/windows.scss';

@mixin font {
  font-family: 'Lato', sans-serif;
}

@mixin text10 {
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  font-weight: 400;
}
@mixin text12 {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 400;
}
@mixin text13 {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: 400;
}
@mixin text14Thin {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 10;
}
@mixin text15 {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 15px;
}
@mixin text16 {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
@mixin text18 {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
@mixin text19 {
  font-family: 'Lato', sans-serif;
  font-size: 19px;
}
@mixin text20 {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}
@mixin text20Bold {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: 700;
}
@mixin text21 {
  font-family: 'Lato', sans-serif;
  font-size: 21px;
}
@mixin text23 {
  font-family: 'Lato', sans-serif;
  font-size: 23px;
}
@mixin text24 {
  font-family: 'Lato', sans-serif;
  font-size: 24px;
}
@mixin text24Bold {
  line-height: 29px;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  font-weight: 700;
}
@mixin text25 {
  font-family: 'Lato', sans-serif;
  font-size: 25px;
}
@mixin text26Bold {
  font-family: 'Lato', sans-serif;
  font-size: 26px;
  font-weight: 700;
}
@mixin text28 {
  font-family: 'Lato', sans-serif;
  font-size: 28px;
}
@mixin text28Bold {
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
}
@mixin text14 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}
@mixin text15Thin {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
}
@mixin text15Bold {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 15px;
}
@mixin text16Bold {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}
@mixin text18Bold {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 700;
}
@mixin text19Bold {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
}
@mixin text21Bold {
  font-family: 'Lato', sans-serif;
  font-size: 21px;
  font-weight: 700;
}
@mixin text22Bold {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 700;
}
@mixin title24Bold {
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  font-weight: 700;
}
@mixin title28Bold {
  line-height: 34px;
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  font-weight: 700;
}
@mixin title28 {
  line-height: 34px;
  font-family: 'Lato', sans-serif;
  font-size: 28px;
}
@mixin title30Bold {
  font-family: 'Lato', sans-serif;
  font-size: 30px;
  font-weight: 700;
}
@mixin title32Bold {
  font-family: 'Lato', sans-serif;
  font-size: 32px;
  font-weight: 800;
}
@mixin title42Black {
  font-family: 'Lato', sans-serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 50px;
}
@mixin title50Bold {
  font-family: 'Lato', sans-serif;
  font-size: 50px;
  font-weight: 800;
}
@mixin title52Bold {
  font-family: 'Lato', sans-serif;
  font-size: 52px;
  font-weight: 800;
}
@mixin title60Bold {
  font-family: 'Lato', sans-serif;
  font-size: 60px;
  font-weight: 800;
}

@mixin progressBar($countSteps, $width: 100%, $colorChosen: $BrinkPink) {

  .ProgressBar {
    width: $width;
    max-width: 1052px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    box-sizing: border-box;

    &-Line {
      width: 100%;
      height: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .ProgressBar-Step:first-child{
        border-radius: 5px 0 0 5px;
      }

      & > .ProgressBar-Step:nth-child(3){
        display: none;

        @include tablet {
          display: flex;
        }
      }

      & > .ProgressBar-Step:nth-child(5){
        display: none;

        @include tablet {
          display: flex;
        }
      }

      & > .ProgressBar-Step:nth-child(8){
        display: none;

        @include tablet {
          display: flex;
        }
      }

      & > .ProgressBar-Step:nth-child(10){
        border-radius: 0 5px 5px 0;
        border-right: 0;
      }

      & > .ProgressBar-Step:nth-child(11){
        width: 0;
        border-left: 0;
        border-right: 0;

        .Description {
          left: 0;
          transform: translate(-50%, 0);
        }
      }
    }

    &-Step {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 10px;
      transition: width 0.3s, height 0.3s;
      background: #E4E8F2;
      position: relative;
      border-left: 1px solid #FEFEFE;
      border-right: 1px solid #FEFEFE;
      @include mobile{
        height: 5px;
      }

      &_done {
        background-color:  #AFC3D5;

        &:after {
          background-color: #1C2A40;
        }
      }

      .Description {
        position: absolute;
        top: 24px;
        left: 60px;
        color: $GullGray;
        white-space: pre;
        text-align: center;
        transform: translate(-100%, 0);

        @include tablet{
          display: none;
        }

        & > p:first-child {
          font-weight: 700;
        }

        @include tablet {
          display: none;
        }
      }

      &_chosen {

        .Description {
          color:$CloudBurst;
        }

        &:after {
          background-color: $colorChosen;
          width: 14px;
          height: 14px;
        }

        @include tablet {

          &:after {
            background-color: #1C2A40;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1230px){

  .Description{
    transform: initial !important;
    left: 0 !important;
  }
}

@mixin loadingForm($rootClass, $width: 100%) {
  #{$rootClass}-Loading-Block {
    display: flex;
    flex-direction: column;
    height: 500px;
    width: $width;
    min-width: $width;
    justify-content: center;
    align-items: center;
  }

  #{$rootClass}-Loading-Text {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $CloudBurst;
  }

  #{$rootClass}-Loading-Points {
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-bottom: 10px;

    & > div {
      width: 11px;
      height: 11px;
      background-color: $CloudBurst;
      border-radius: 50%;
      margin: 0px 5px;
      transition: background-color 200ms;

      &:nth-child(1n) {
        opacity: 0.2;
      }

      &:nth-child(2n) {
        opacity: 0.4;
      }

      &:nth-child(3n) {
        opacity: 0.8;
      }

      &:nth-child(4n) {
        opacity: 1;
      }
    }
  }
}

@mixin media($resolution) {
  @media (max-width: #{$resolution}){
    @content;
  }
}
